import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setReduxType } from 'src/redux/app/appActions';
import { initAppOnClientSide } from 'src/redux/app/appOperations/initAppOnClientSide';
import { useAppSelector } from 'src/redux/hooks';
import { checkUserLogin } from 'src/redux/user/userActions';
import { setConfirmedAge } from 'src/redux/userProfile/userProfileActions';

export default function AppInitComponent() {
  const dispatch = useDispatch();

  const language = useAppSelector((s) => s.app.language);
  const isLoginChecked = useAppSelector(s => s.user.isLoginChecked);

  useEffect(() => {
    (async function () {
      dispatch(setReduxType('client'));

      /**
       * @todo Global refactoring of age confirmation logic needed.
       */
      dispatch(setConfirmedAge(window.localStorage.getItem('confirmedAge')));

      if(!isLoginChecked) {
        await dispatch(checkUserLogin());
      }
      await dispatch(initAppOnClientSide());

      window.localStorage.setItem('language', language);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
