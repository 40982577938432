import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getCartPrice } from 'src/redux/cart/clientCart/operations/getCartPrice';
import { selectStairsDiscount } from 'src/redux/cart/general/cartSelectors';
import { useAppSelector } from 'src/redux/hooks';

export default function ClientCartPriceWatcher() {
  const dispatch = useDispatch();

  const dataSignature = useAppSelector((s) => s.cart.dataSignature);
  const cartIsClient = useAppSelector((s) => s.app.cartType) === 'client';
  const cartModalIsOpen = useAppSelector((s) => s.modals.cartModal.isOpen);
  const isCartInitialized = useAppSelector((s) => s.cart.isCartInitialized);
  const stairsDiscount = useAppSelector(selectStairsDiscount);

  const updateCartPrice = useMemo(() => {
    return debounce(() => {
      dispatch(getCartPrice(true));
    }, 400);
  }, [dispatch]);

  const withFixedDeliveryDiscount = stairsDiscount?.applicable_for === 'fixed_delivery_fee';

  const needUpdate =
    cartIsClient &&
    !cartModalIsOpen &&
    isCartInitialized &&
    withFixedDeliveryDiscount;

  useEffect(() => {
    if (needUpdate) {
      updateCartPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSignature, dispatch]);

  return null;
}
