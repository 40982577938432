import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import { selectStoreId } from '../general/cartSelectors';
import { CartState } from '../general/cartTypes';
import { ClientCartPrice, ClientCartRequest } from './clientCartTypes';

const postClientCartRequest = createAction('clientCart/postClientCartRequest');
const postClientCartSuccess = createAction<ClientCartPrice>('clientCart/postClientCartSuccess');
const postClientCartError = createAction<RSAAActionErrorPayload>('clientCart/postClientCartError');

const requestPostClientCart = (body: ClientCartRequest) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const storeId = selectStoreId(state);
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(createRsaaAction<RootState, ClientCartPrice, undefined>({
    method: 'POST',
    credentials: 'include',
    endpoint: `${apiRoot}/stores/${storeId}/cart/`,
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: JSON.stringify(body),
    types: [
      postClientCartRequest.type,
      postClientCartSuccess.type,
      postClientCartError.type,
    ],
  }));
};

export default requestPostClientCart;

export const addRequestPostClientCartCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(postClientCartRequest, (state) => {
      state.isFetching = true;
    })
    .addCase(postClientCartSuccess, (state, action: { payload: ClientCartPrice }) => {
      state.isFetching = false;
      state.errors = null;
      state.cartPrice = action.payload;
    })
    .addCase(postClientCartError, (state, action: { payload: RSAAActionErrorPayload }) => {
      const errors = getResponseErrors(action.payload);
      state.isFetching = false;

      // do not add error of cart overweight
      // this error is handled in another way
      if (errors[0].error_code === 4342) {
        state.cartPrice.total_weight = errors[0].details.current;
      } else {
        state.errors = errors;
      }
    });
};
