import { Dispatch } from 'redux';
import { requestGetFiltersLikeCategoriesList } from 'src/redux/categories/categoriesOperations/getFiltersLikeCategoriesList';
import getAllCategories from '../../categories/categoriesOperations/getAllCategories';
import { RootState } from '../../reducers';
import { getUserProfile } from '../../userProfile/userProfileOperations/getUserProfile';

/**
 * Loads all the necessary data for the application.
 * These requests were taken from the old implementation of the checkUserLogin operation.
 */
export const fetchGeneralAppData = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const userIsLoggedIn = getState().user.isLoggedIn;

  if (userIsLoggedIn) {
    await dispatch(getUserProfile());
  }

  return Promise.all([
    // list of categories is loaded on mounting CategoriesMenu component
    // but this component is not rendered in checkout (and maybe somewhere else).
    // In checkout list of categories is needed for breadcrumbs in BigProductCart
    dispatch(getAllCategories()),
    dispatch(requestGetFiltersLikeCategoriesList()),
  ]);
};
