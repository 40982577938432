import Image from 'next/image';
import { MessengerCloseButtonProps, SocialMessenger, ZendeskButtonProps } from '../Messengers.types';
import MessengerCloseButton from './MessengerCloseButton';
import MessengerLauncherButton from './MessengerLauncherButton';
import styles from './MessengersList.styles';

export type MessengersListVariant = 'line' | 'column';

interface MessengersListProps {
  socials: SocialMessenger[];
  onClick?: () => void;
  variant: MessengersListVariant;
  zendeskButtonProps?: ZendeskButtonProps;
  closeButtonProps?: MessengerCloseButtonProps;
}

const MessengersList = (props: MessengersListProps) => {
  const { socials, onClick, variant, zendeskButtonProps, closeButtonProps } = props;

  return (
    <div
      className={`MessengersList MessengersList_${variant}`}
      data-testid='messengers_list'
    >
      {socials.map(socialMessenger => (
        <a
          key={`Social ${socialMessenger.name}`}
          data-marker={`${socialMessenger.name} Button`}
          className='MessengersList__button'
          target='_blank'
          rel='noreferrer'
          href={socialMessenger.link}
          title={socialMessenger.name}
          onClick={onClick}
        >
          <Image
            src={socialMessenger.img_url}
            alt={socialMessenger.name}
            width={40}
            height={40}
          />
        </a>
      ))}

      {zendeskButtonProps && (
        <div className='MessengersList__button'>
          <MessengerLauncherButton {...zendeskButtonProps} />
        </div>
      )}

      {closeButtonProps && (
        <div
          data-marker='Close Messengers'
          className='MessengersList__button'
        >
          <MessengerCloseButton {...closeButtonProps} />
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default MessengersList;
