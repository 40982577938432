import Cookies from 'js-cookie';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import ScMail from 'src/components/containers/ScMail/ScMail';
import GtmNoScript from 'src/components/views/GtmNoScript/GtmNoScript';
import GtmScript from 'src/components/views/GtmScript/GtmScript';
import { RenderAfterInteractOrTimer } from 'src/modules/interact-watcher';
import { useAppSelector } from 'src/redux/hooks';
import useToggles from 'src/services/toggles/useToggles';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { updateAffiliateProgramsCookie } from 'src/utils/marketing/affiliatePrograms';
import { sendDimensionSet, sendReferrerUrl } from 'src/utils/marketing/enhancedEcommerce';
import getGoogleCid from 'src/utils/marketing/getGoogleCid';
import { MessengersApp } from '../../../messengers';
import EsputnikUserInfo from './EsputnikUserInfo';

export default function AppMarketing() {
  const { chain, host, marketing } = useAppConfig();
  const { WITH_COOKIE_POLICY } = useToggles();
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const store = useAppSelector((s) => s.storeManagement.store);

  const router = useRouter();

  const {
    GOOGLE_SITE_VERIFICATION,
    CHAIN_GTM_ID,
    CROSS_DOMAIN_GTM_ID,
    METRO_OWN_GTM_ID,
    LOAD_SCRIPT_DELAY,
  } = marketing;

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      sendReferrerUrl(window.location.href);
    });

    const clientId = getGoogleCid();

    sendDimensionSet(chain, store.id, clientId);

    updateAffiliateProgramsCookie(router.query, host, WITH_COOKIE_POLICY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cookiesAllowed = Cookies.get('cookiesAllowed') === 'true';
  const scriptsAreAllowed = !WITH_COOKIE_POLICY || cookiesAllowed;

  const renderDelay = LOAD_SCRIPT_DELAY ? Number(LOAD_SCRIPT_DELAY) : null;

  return (
    <Fragment>
      <Head>
      {GOOGLE_SITE_VERIFICATION && (
        <meta name="google-site-verification" content={GOOGLE_SITE_VERIFICATION} />
      )}
      </Head>

      {scriptsAreAllowed && (
        <RenderAfterInteractOrTimer renderAfter={renderDelay}>
          {CHAIN_GTM_ID && <GtmScript gtmId={CHAIN_GTM_ID} />}
          {CHAIN_GTM_ID && <GtmNoScript gtmId={CHAIN_GTM_ID} />}

          {CROSS_DOMAIN_GTM_ID && <GtmScript gtmId={CROSS_DOMAIN_GTM_ID} />}
          {CROSS_DOMAIN_GTM_ID && <GtmNoScript gtmId={CROSS_DOMAIN_GTM_ID} />}

          {METRO_OWN_GTM_ID && <GtmScript gtmId={METRO_OWN_GTM_ID} />}
          {METRO_OWN_GTM_ID && <GtmNoScript gtmId={METRO_OWN_GTM_ID} />}
        </RenderAfterInteractOrTimer>
      )}

      <ScMail />
      {isLoggedIn && <EsputnikUserInfo />}

      <MessengersApp />
    </Fragment>
  );
}
