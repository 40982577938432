import { createReducer } from '@reduxjs/toolkit';
import getDefaultStore from './getDefaultStore';
import { addGetStoreById } from './requestGetStoreById';
import { addSetStoreCases } from './setStore';
import { StoreManagementState } from './types';

export const storeManagementInitialState: StoreManagementState = {
  store: getDefaultStore(),
  status: 'idle',
  error: null,
};

const reducer = createReducer(storeManagementInitialState, builder => {
  addSetStoreCases(builder);
  addGetStoreById(builder);
});

export default reducer;
