import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Store } from '../apiTypes';
import { StoreManagementState } from './types';

const setStore = createAction<Store>('storeManagement/setStore');

export default setStore;

export function addSetStoreCases(
  builder: ActionReducerMapBuilder<StoreManagementState>,
) {
  builder.addCase(setStore, (state, action) => {
    state.store = action.payload;
  });
}