import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { createAction as createCacheAction } from '../apiCacheMiddleware';
import { RSAAActionErrorPayload, Store } from '../apiTypes';
import { RootState } from '../reducers';
import getDefaultStore from './getDefaultStore';
import { StoreManagementState } from './types';

interface Meta {
  status: number;
  statusText: string;
}

const getStoreByIdRequest = createAction('storeManagement/getStoreByIdRequest');

const getStoreByIdSuccess = createAction<Store>(
  'storeManagement/getStoreByIdSuccess',
);

const getStoreByIdError = createAction<RSAAActionErrorPayload>(
  'storeManagement/getStoreByIdError',
);

const requestGetStoreById = (storeId: string) => async (
  dispatch: Dispatch, getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const action = createCacheAction<RootState, Store, Meta>(
    {
      cacheKey: `store-${storeId}-${language}`,
      successActionType: getStoreByIdSuccess.type,
    },
    {
      method: 'GET',
      endpoint: `${apiRoot}/stores/${storeId}/`,
      headers: {
        'Accept-Language': language,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        getStoreByIdRequest.type,
        getStoreByIdSuccess.type,
        {
          type: getStoreByIdError.type,
          meta: (action, state, res) => {
            return {
              status: res.status,
              statusText: res.statusText,
            };
          },
        },
      ],
    },
  );

  return dispatch(action);
};

export default requestGetStoreById;

export function addGetStoreById(
  builder: ActionReducerMapBuilder<StoreManagementState>,
) {
  builder
    .addCase(getStoreByIdRequest, state => {
      state.status = 'loading';
      state.error = null;
    })
    .addCase(getStoreByIdSuccess, (state, action) => {
      state.store = action.payload;
      state.status = 'succeeded';
      state.error = null;
    })
    .addCase(getStoreByIdError, (state, action) => {
      state.store = getDefaultStore();
      state.status = 'failed';
      state.error = action.payload;
    });
}
