import { Store } from '../apiTypes';

function getDefaultStore() {
  const store: Store = {
    id: 'default',
  };
  
  return store;
}

export default getDefaultStore;