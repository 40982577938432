import { ReactNode } from 'react';
import { Offer } from 'src/redux/apiTypes';
import { useAppSelector } from '../../redux/hooks';
import HeaderPromotionContext from './HeaderPromotionContext';
import useHeaderPromotionLogic from './useHeaderPromotionLogic';

interface HeaderPromotionProviderProps {
  children: ReactNode;
  headerPromotion: Offer | null;
}

const HeaderPromotionProvider = ({ children, headerPromotion }: HeaderPromotionProviderProps) => {
  const language = useAppSelector(s => s.app.language);
  const headerPromotionLogic = useHeaderPromotionLogic(headerPromotion, language);

  return (
    <HeaderPromotionContext.Provider value={headerPromotionLogic}>
      { children }
    </HeaderPromotionContext.Provider>
  );
};

export default HeaderPromotionProvider;
